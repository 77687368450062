import React, { useState } from 'react';
import ProjectModal from './ProjectModal';
import Remote from '../assets/Projects/Remote.png';
import Planner from '../assets/Projects/Planner.png';
import Website from '../assets/Projects/Website.png';
import Game from '../assets/Projects/Game.png';
import Vedute from '../assets/Projects/Vedute.png';
import Catfit from '../assets/Projects/Catfit.png';
import MijnOV from '../assets/Projects/Mijn-OV.png'

const Projects = () => {
    const [showModal, setShowModal] = useState(false);
    const [selectedProject, setSelectedProject] = useState(null);

    const projects = [
        {
            title: "IR Remote control",
            image: Remote,
            description: "This was my first project at CMGT, here we mainly learned the basics of coding and using a laser cutter. The remote could control different modules we made.",
            github: "https://github.com/your-username/remote-control-repo"
        },
        {
            title: "Appointment planner",
            image: Planner,
            description: "First time using PHP and connecting it to a database. Here users could make a reservation and they could be viewed in a dashboard.",
            github: "https://github.com/your-username/appointment-planner-repo"
        },
        {
            title: "Accessibility website",
            image: Website,
            description: "This project made me learn a css framework called Bulma. We made the website to inform about vision impaired people." +
                "To make decisions on our website we had to interview vision impaired people to get their input on what information should be displayed on the website. ",
            github: "https://github.com/colorants/CLE3-Accessibility"
        },
        {
            title: "Web-game",
            image: Game,
            description: "A webgame using the Excalibur framework. First we made our own games using it and later we made one as a group. A tower defense game.",
            github: "https://github.com/your-username/web-game-repo"
        },
        {
            title: "Art platform marketing",
            image: Vedute,
            description: "In this case we made a market strategy for an art platform including instagram templates and a website for their art collection.",
            github: "https://github.com/your-username/marketing-platform-repo"
        },
        {
            title: "Chat Bot for outfits",
            image: Catfit,
            description: "First time using AI, using LangchainJS to create a chatbot that can give you outfits.",
            github: "https://github.com/colorants/JavaScriptAI"
        },
        {
            title: "Mijn-OV",
            image: MijnOV,
            description: "An AI chatbot that helps you make public transport trips, using different api's for the best reliability.",
            github: "https://github.com/mijn-ov/mijn-ov"
        }
    ];


    const openModal = (project) => {
        setSelectedProject(project);
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
        setSelectedProject(null);
    };

    return (
        <div name='projects' className=' md:h-screen w-full bg-[#333745] text-gray-300'>
            <div className='max-w-[1000px] mx-auto pb-4 flex flex-col justify-center w-full h-full'>
                <div className='pt-8'>
                    <p className='text-4xl font-bold inline border-b-4 text-gray-300 border-[#7189FF]'>Projects</p>
                    <p className='py-6'>Check out my projects (Listed oldest to newest). All these projects where done at school.</p>
                </div>

                <div className='grid sm:grid-cols-2 md:grid-cols-3 gap-4'>
                    {projects.map((project, index) => (
                        <div
                            key={index}
                            style={{backgroundImage: `url(${project.image})`}}
                            className='shadow-lg shadow-[#040c16] group container rounded-md flex justify-center items-center mx-auto content-div'
                            onClick={() => openModal(project)}
                        >
                            {/* hover effects */}
                            <div className='opacity-0 group-hover:opacity-100'>
                                <span className='text-2xl font-bold text-black tracking-wider'>{project.title}</span>
                                <p className='mx-auto'> click for more info</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            {showModal && <ProjectModal project={selectedProject} onClose={closeModal} />}
        </div>
    );
};

export default Projects;
