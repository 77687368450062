import React from "react";

import JavaScript from "../assets/javascript.png";
import ReactIcon from "../assets/react.png";
import CSS from "../assets/css.png";
import HTMLIcon from "../assets/html.png";
import TailwindIcon from "../assets/tailwind.png";
import Git from "../assets/github.png";

const Skills = () => {
    return (
        <div name='skills' className='w-full h-screen bg-[#333745] text-gray-300 '>
            {/*container*/}
            <div className='max-w-[1000px] mx-auto p-4 flex flex-col justify-center w-full h-full '>
                <div>
                    <p className='text-4xl font-bold inline border-b-4 border-[#7189FF]'>
                        Skills{" "}
                    </p>
                    <p className='py-4'> These are the tools I am learning and using.</p>
                </div>

                <div className='w-full grid grid-cols-4 sm:grid-cols-2 gap-4 text-center sm:pb-8'>
                    {skills.map((skill, index) => (
                        <div
                            key={index}
                            className='shadow-md hover:shadow-lg transition duration-300'
                        >
                            <img className='my-3 w-20 mx-auto' src={skill.icon} alt={skill.alt} />
                            <p className='my-3'>{skill.name}</p>
                            {/* Description */}
                            <p className='text-sm m-2'>{skill.description}</p>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Skills;

const skills = [
    {
        name: "HTML",
        icon: HTMLIcon,
        alt: "html",
        description: "2 years ago I knew nothing of HTML and now I can create a website on my own. To be able to do this gave me a lot of satisfaction."
    },
    {
        name: "CSS",
        icon: CSS,
        alt: "css",
        description: "While learning HTML is learned CSS and styling some things. I am stil learning frameworks like Tailwind CSS."
    },
    {
        name: "JavaScript",
        icon: JavaScript,
        alt: "javascript",
        description: "Right now I learnt some basic Javascript. Mainly for front-end stuff, but I am also learning Node.js for back-end."
    },
    {
        name: "React",
        icon: ReactIcon,
        alt: "react",
        description: "At the moment I use React for some of my projects. And also for this website. I am still learning and improving my abilities."
    },
    {
        name: "Tailwind",
        icon: TailwindIcon,
        alt: "tailwind",
    },
    {
        name: "GitHub",
        icon: Git,
        alt: "github",
    },
];
