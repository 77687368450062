import React from 'react';

import {Link} from "react-scroll";

const scrollDown = () => {

}

const Home = () => {
    return (
        <div name="home" className='w-full h-screen bg-[#333745]'>

        {/*    container */}
            <div className='max-w-[1000px] mx-auto px-8 flex flex-col justify-center h-full '>
                <p className='bg-gradient-to-r from-blue-500 via-green-200 to-indigo-800 text-transparent bg-clip-text'> Hi, my name is </p>
                <h1 className='text-4xl sm:text-7xl font-bold text-gray-300'> Viggo van der Ven</h1>
                <h2 className='text-4xl sm:text-6xl font-bold text-gray-500'> I'm a Creative Media & Game Technologies Student at Hogeschool Rotterdam. </h2>
                <p className='text-gray-500 py-4 max-w-[700px]'> Learning to code and a passion for cars...</p>
                <div>

                    <button className='text-white group border-2 px-6 py-3 my-2 flex items-center hover:bg-[#7189FF] hover:border-[#7189FF] duration-300'>
                        <Link to="projects" smooth={true} duration={500}>
                            <a href={'/projects'}>My projects</a>
                        </Link>
                    </button>


                </div>

            </div>

        </div>
    )
}

export default Home;