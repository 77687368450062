import React, { useRef, useEffect } from 'react';

const ProjectModal = ({ project, onClose }) => {
    const modalRef = useRef(null);

    useEffect(() => {
        // Function to handle clicks outside the modal
        const handleClickOutside = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                onClose(); // Call onClose function when clicked outside
            }
        };

        // Add event listener when component mounts
        document.addEventListener('mousedown', handleClickOutside);

        // Remove event listener when component unmounts
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [onClose]);

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-60">
            <div ref={modalRef} className="bg-gray-600 p-5 rounded-lg max-w-md relative">
                <h2 className="text-2xl font-bold mt-1 mb-4 text-center">{project.title}</h2>
                <p className="mb-6 max-w-[600px] text-center">{project.description}</p>
                <img className='m-5 mx-auto pb-7 rounded-lg' src={project.image}  alt=''/>
                <button className="bg-gray-300 hover:bg-red-400 text-gray-800 font-bold py-2 px-4 rounded absolute bottom-0 left-0 m-4 mt-4" onClick={onClose}>Close</button>
            </div>
        </div>
    );
};

export default ProjectModal;
