import React from 'react';

const About = () => {
    return (
        <div name='about' className='w-full h-screen bg-[#333745] text-gray-300'>
            <div className='flex flex-col justify-center items-center w-full h-full'>
               <div className='max-w-[1000px] w-full px-4 grid grid-cols-2 gap-8'>
                   <div className='sm:text-right pb-8'>
                      <p className='text-4xl font-bold inline border-b-4 border-[#7189FF]'>
                          About
                      </p>
        </div>
                   <div></div>
                   </div>
        <div className='max-w-[1000px] w-full grid sm:grid-cols-2 gap-8 px-4'>
            <div className='sm:text-right text-4xl font-bold py-3'>
                <p>Hi. I'm Viggo nice to meet you. Please take a look around.</p>
            </div>
            <div >
                <p>Ever since I was young, I've been fascinated by technology, and that hasn't changed. I've only been into "coding" for about two years, mostly working on web development. Every day, I learn more and get better at it. When I'm not coding, I like playing games and messing around with my car. I also make sure to go to the gym and hang out with friends in my free time.</p>
            </div>
        </div>
        </div>
        </div>

    )
}

export default About;